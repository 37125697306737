@tailwind base;
@tailwind components;
@tailwind utilities;

.has-markdown-inside {
  @apply leading-relaxed text-sm;
}

@screen sm {
  .has-markdown-inside {
    @apply text-sm;
  }
}

@screen lg {
  .has-markdown-inside {
    @apply text-base;
  }
}

/* Headers */
.has-markdown-inside h1,
.has-markdown-inside h2 {
  @apply text-xl mb-6 font-semibold;
}
.has-markdown-inside h3,
.has-markdown-inside h4,
.has-markdown-inside h5,
.has-markdown-inside h6 {
  @apply text-lg mb-3 font-semibold;
}

@screen sm {
  .has-markdown-inside h1,
  .has-markdown-inside h2 {
    @apply text-2xl;
  }
  .has-markdown-inside h3,
  .has-markdown-inside h4,
  .has-markdown-inside h5,
  .has-markdown-inside h6 {
    @apply text-xl;
  }
}

/* Links */
.has-markdown-inside a {
  @apply text-neutral-100;
  @apply underline;
}
.has-markdown-inside a:hover {
  @apply underline;
  @apply text-white;
}
/* Paragraph */
.has-markdown-inside p {
  @apply mb-4;
}
/* Lists */
.has-markdown-inside ul,
.has-markdown-inside ol {
  @apply mb-4 ml-8;
}
.has-markdown-inside li {
  @apply mb-4;
}
.has-markdown-inside li > p,
.has-markdown-inside li > ul,
.has-markdown-inside li > ol {
  @apply mb-0;
}
.has-markdown-inside ol {
  @apply list-decimal;
}
.has-markdown-inside ul {
  @apply list-disc;
}
/* Blockquotes */
.has-markdown-inside blockquote {
  @apply p-2 mx-6 mb-4 border-l-4 border-gray-400 italic;
}
.has-markdown-inside blockquote > p {
  @apply mb-0;
}
/* Tables */
.has-markdown-inside td,
.has-markdown-inside th {
  @apply px-2 py-1 border border-gray-400;
}
.has-markdown-inside tr:nth-child(odd) {
  @apply bg-gray-100;
}
.has-markdown-inside table {
  @apply mb-6;
}

/* Wrap any inline highlights `that are really long`, but don't modify
   the setting for codeblocks (inside ```), which are rendered in as:
   <pre><code>...
*/
.has-markdown-inside :not(pre) > code.language-text {
  white-space: pre-line;
}
